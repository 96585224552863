<template>
  <div class="flex flex-col mb-16">
    <app-section-title
      :title="t('ttmt.extranet.dashboard.sections.stats')"
      class="mb-9"
    />

    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
      <app-stat-card-wrapper
        :loading="institutionsCountLoading"
        :icon-props="{ library: 'coolicon', name: 'building', size: 'text-6xl' }"
        background-image="/images/extranet/stats/institutions.jpg"
        :label="t('ttmt.extranet.dashboard.stats.institutions', institutionsCount)"
        :value="institutionsCount"
        route-name="institutions"
      />

      <app-stat-card-wrapper
        :loading="reservationsCountLoading"
        :icon-props="{ library: 'coolicon', name: 'flag_outline', size: 'text-6xl' }"
        background-image="/images/extranet/stats/reservations.jpg"
        :label="t('ttmt.extranet.dashboard.stats.reservations', reservationsCount)"
        :value="reservationsCount"
        route-name="institutions"
      />
    </div>

    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <last-claims
        :resources="claims"
        :loading="claimsLoading"
        margins="mt-8"
      />

      <last-reservations
        :resources="reservations"
        :loading="reservationsLoading"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  fetchStatInstitutions as apiFetchStatInstitutions,
  fetchLastClaims,
  fetchStatReservations as apiFetchStatReservations,
  fetchLastReservations,
} from '@shared/http/api'
import AppStatCardWrapper from '@extranet/components/ui/stat_card/AppStatCardWrapper.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import LastClaims from '@extranet/components/dashboard/shared/LastClaims.vue'
import LastReservations from '@extranet/components/dashboard/manager/LastReservations.vue'

const { t } = useI18n()

// ---------- INSTITUTIONS ----------

const institutionsCount = ref(0)
const institutionsCountLoading = ref(true)

function fetchInstitutionsCount() {
  apiFetchStatInstitutions()
    .then((response) => {
      institutionsCount.value = response.data.data?.value
    })
    .finally(() => {
      institutionsCountLoading.value = false
    })
}

// ---------- CLAIMS ----------

const claims = ref([])
const claimsLoading = ref(true)

function fetchClaims() {
  fetchLastClaims()
    .then((response) => {
      claims.value = response.data.data ?? []
    })
    .finally(() => {
      claimsLoading.value = false
    })
}

// ---------- RESERVATIONS ----------

const reservations = ref([])
const reservationsCount = ref(0)
const reservationsLoading = ref(true)
const reservationsCountLoading = ref(true)

function fetchReservations() {
  fetchLastReservations()
    .then((response) => {
      reservations.value = response.data.data ?? []
    })
    .finally(() => {
      reservationsLoading.value = false
    })
}

function fetchReservationsCount() {
  apiFetchStatReservations()
    .then((response) => {
      reservationsCount.value = response.data.data?.value
    })
    .finally(() => {
      reservationsCountLoading.value = false
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchInstitutionsCount()
  fetchClaims()
  fetchReservations()
  fetchReservationsCount()
})
</script>
